<template>
    <activity-wrapper icon="location-alt" :clock="daysInShort" :format-clock="false">
      <template #action>
        <n-action-sheet #default="{closeSheet}">
          <n-action-sheet-item v-if="isTripType('PASSENGER')" icon="trash-can" @click.native="closeSheet(deleteRecurringPassengerRequest)">
            {{ $t('overview.recurring.actionSheet.deletePassenger') }}
          </n-action-sheet-item>
          <n-action-sheet-item v-if="isTripType('DRIVER')" icon="trash-can" @click.native="closeSheet(deleteRecurringDriverTrip)">
            {{ $t('overview.recurring.actionSheet.deleteDriver') }}
          </n-action-sheet-item>
          <n-action-sheet-item icon="vacation" @click.native="closeSheet(() => $router.push({'name': 'vacation'}))">
            {{ $t('overview.actionSheet.vacationSettings') }}
          </n-action-sheet-item>
        </n-action-sheet>
      </template>
      <template #status>
        {{ statusText }}
      </template>
      <template #steps>
        <step v-for="(step, index) in steps" :key="index" :time="step.time" :address="step.address"></step>
      </template>
      <template #buttons>
        <n-button size="lg" class="span-6" type="outlined" inverted @click="openEdit">{{ $t('overview.recurring.editButton') }}</n-button>
      </template>
    </activity-wrapper>
</template>
<script>
import ActivityWrapper from '@/components/shared/overview/activityWrapper';
import Step from '@/components/shared/overview/parts/step';
export default {
  components: { ActivityWrapper, Step },
  props: {
    trip: {
      type: Object,
      required: true,
    },
    times: {
      type: Object,
      required: true,
    },
  },
  computed: {
    daysInShort() {
      return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
        .filter(day => this.trip[`${day}_leave`] != null)
        .map(x => this.$t(`daysShort.${x}`))
        .join(', ')
    },
    statusText() {
      const stopCount = this.trip.leave_stop_count;
      const stops = this.isTripType('DRIVER') ? `, ${this.$tc('overview.recurring.stops', stopCount)}` : ''
      return `${this.$tc('overview.recurring.seats', this.trip.seats)}${stops}`;
    },
    steps() {
      return [
        {
          time: this.times.leaving ? this.times.leaving.substring(0, 5) : '',
          address: `${this.trip.from_street}, ${this.trip.from_city}`
        },
        {
          time: '',
          address: `${this.trip.to_street}, ${this.trip.to_city}`
        }
      ];
    },
  },
  methods: {
    deleteRecurringDriverTrip() {
      this.$modal.show('dialog', {
        title: this.$t('overview.recurring.deleteDialog.titleDriver'),
        text: this.$t('overview.recurring.deleteDialog.descriptionDriver'),
        cancel: true,
        color: 'error',
        success: {
          text: this.$t('overview.recurring.deleteDialog.successButton'),
          handler: () => {
            this.$emit('delete');
          }
        },
      });
    },
    deleteRecurringPassengerRequest() {
      this.$modal.show('dialog', {
        title: this.$t('overview.recurring.deleteDialog.titlePassenger'),
        text: this.$t('overview.recurring.deleteDialog.descriptionPassenger'),
        cancel: true,
        color: 'error',
        success: {
          text: this.$t('overview.recurring.deleteDialog.successButton'),
          handler: () => {
            this.$emit('delete');
          }
        },
      });
    },
    openEdit() {
      this.$router.push({ name: 'edit-recurring', params: { recurringTrip: this.trip } });
    },
    isTripType(type) {
      return this.trip.role === type;
    },
  }
}
</script>
