<template>
  <div class="date-time-wrapper">
    <div class="inner-wrapper" @click="open">
      <n-icon v-if="icon" :name="icon" size="sm" :color="disabled ? 'grey-dark' : 'accent'" outline />
      <div class="selectedOption">
        <n-text preset="sub" v-if="value" :color="disabled ? 'grey-dark' : 'accent'">{{ formatDate(value) }}</n-text>
        <n-text v-else-if="!value && standard" preset="sub">{{ standard }}</n-text>
        <n-text v-else-if="placeholder" preset="sub" color="grey-dark">{{ placeholder }}</n-text>
      </div>
      <n-icon name="chevron-down" outline size="sm" :color="disabled ? 'grey-dark' : 'accent'" />
      <slot />
    </div>
      <datetime
        v-bind="$attrs"
        ref="picker"
        input-style="display: none;"
        :title="title"
        :auto="auto"
        :value="value ? value.toISOString() : null"
        @input="$emit('input', $event ? new Date($event) : null)"
        @close="$emit('close')"
      />
  </div>
</template>

<script>
import { format } from '@/vendor/date-fns';
export default {
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
    },
    value: {
      type: Date,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    standard: {
      type: String,
      required: false,
    },
    stringFormat: {
      type: String,
      default: 'd. MMM.',
    },
    placeholder: {
      type: String,
    },
    title: {
      type: String,
    },
    auto: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    format,
    open(e) {
      if (this.disabled) {
        return;
      }
      this.$refs.picker.open(e);
    },
    formatDate(val) {
      return format(val, this.stringFormat);
    }
  },
};
</script>


<style lang="scss">
@import "@/style/styleguide.scss";

.date-time-wrapper {
  --color: var(--color-accent);
  height: 17px;
  position: relative;
  display: flex;
  &.disabled {
    --color: var(--color-grey-dark);
  }
  .inner-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .selectedOption {
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    color: var(--color);
    margin: 0 5px 0 0;
  }
}

.vdatetime-popup__header,
.vdatetime-calendar__month__day--selected > span > span,
.vdatetime-calendar__month__day--selected:hover > span > span {
  background: var(--color-accent) !important;
}

.vdatetime-year-picker__item--selected,
.vdatetime-time-picker__item--selected,
.vdatetime-popup__actions__button {
  color: var(--color-accent) !important;
}
</style>
