<template>
   <n-grid class="span-6">
    <n-text preset="title" color="accent" :class="trip.driver ? 'span-3' : 'span-6'">{{formatRelativeWithoutTime(trip.departure_time)}}</n-text>
    <template v-if="trip.driver">
      <rating :stars="trip.driver.ratings_average" class="span-2 flex-end"/>
      <profile-picture @click="openProfile" :src="trip.driver.image" />
    </template>
    <n-text color="grey-dark" preset="sub" class="flex-v-center">{{format(departureTime, 'HH:mm')}}</n-text>
    <n-text color="grey-dark" class="span-5">{{tripOrigin.name}}</n-text>
    <template v-if="shifts > 0">
      <n-text color="grey-dark" preset="sub" class="flex-v-center">{{$t('marketplace.via')}}</n-text>
      <n-text color="grey-dark" class="span-5">{{$t('marketplace.shifts', [shifts])}}</n-text>
    </template>
    <n-text color="grey-dark" preset="sub" class="flex-v-center">{{format(arrivalTime, 'HH:mm')}}</n-text>
    <n-text color="grey-dark" class="span-5">{{tripDestination.name}}</n-text>
    <horizontal-schedule class="span-6" :legs="trip.legs">
      <div class="price-display">
        <n-text :striked="price.discounted_price != null" color="accent" preset="label-3" block>{{price.price}}</n-text>
        <n-text v-if="price.discounted_price" color="accent" preset="label-3">{{price.discounted_price}}</n-text>
      </div>
    </horizontal-schedule>
    <n-button block size="lg" @click="$emit('select')">{{ showButtonText }}</n-button>
    <n-grid v-if="seperator" :y-gap="5">
      <n-seperator class="span-6" />
    </n-grid>
  </n-grid>
</template>

<script>
import { formatRelativeWithoutTime, format } from '@/vendor/date-fns';
import { EventBus } from '@/vendor/events';
import ProfilePicture from '@/components/shared/profilePicture';
import HorizontalSchedule from '@/components/shared/horizontalSchedule';
import Rating from '@/components/shared/rating';
import { formatPrice } from '@/vendor/utils';
export default {
  name: 'mainDemandMarketplaceNabogoTrip',
  components: { ProfilePicture, Rating, HorizontalSchedule },
  props: {
    trip: {
      type: Object,
      required: true,
    },
    seperator: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    formatRelativeWithoutTime,
    format,
    openProfile() {
      EventBus.$emit('open-profile', this.trip.driver.id);
    },
  },
  computed: {
    price() {
      return formatPrice(this.trip);
    },
    departureTime() {
      return this.tripOrigin.datetime;
    },
    tripOrigin() {
      const relevantLeg = this.trip.legs
        .find(x => x.type !== 'WALK');

      if (!relevantLeg) {
        return this.trip.legs[0].origin;
      }

      return relevantLeg.origin;
    },
    arrivalTime() {
      return this.tripDestination.datetime;
    },
    tripDestination() {
      const relevantLeg = [...this.trip.legs]
        .reverse()
        .find(x => x.type !== 'WALK');

      if (!relevantLeg) {
        return this.trip.legs[this.trip.legs.legnth - 1].destination;
      }

      return relevantLeg.destination;
    },
    shifts() {
      return this.trip.legs.filter(x => x.type !== 'WALK').length - 1;
    },
    showButtonText() {
      return this.trip.public ? this.$t('marketplace.showTrip') : this.$t('marketplace.bookTrip');
    },
  }
}
</script>

<style lang="scss" scoped>
.price-display {
  display: flex;
  text-align: center;
  flex-direction: column;
  padding-bottom: 0.25rem;
  margin-left: calc(var(--icon-md-size) * -0.5);
}
</style>
