<template>
  <input
    v-on="$listeners"
    v-bind="$attrs"
    @input="$emit('update', $event.target.value)"
    class="input"
    ref="input"
  />
</template>

<script>
export default {
  model: {
    event: 'update',
    prop: 'value',
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/styleguide.scss';

.input {
  height: 48px;
  background-color: var(--color-grey-light);
  border-radius: 9999px;
  border: none;
  color: var(--color-grey-darker);
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: var(--letter-spacing-sm);
  outline: none;
  padding: 12.5px 22.5px;
  width: 100%;
  grid-column: 1 / -1;

  &::placeholder {
    color: var(--color-grey-dark);
    font-style: normal;
    font-weight: 300;
  }
}
</style>
