<template>
  <n-top-safe-spacer class="nav-bar shadow" :class="{ solid: solid }">
    <n-layout>
      <div class="icon-wrapper">
        <n-icon
          @click="goBack"
          name="chevron-left"
          class="flex-start"
          :color="solid ? 'white' : 'accent'"
          outline
        />
      </div>
      <slot name="title">
        <n-text
          preset="title"
          :color="solid ? 'white' : 'accent'"
          class="span-5 text-ellipsis"
          >{{ title }}</n-text
        >
      </slot>

      <n-spinner
        v-if="loading"
        class="self-end"
        :color="solid ? 'white' : 'accent'"
      />
    </n-layout>
  </n-top-safe-spacer>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    solid: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    goBackOverride: {
      type: Function,
    }
  },
  methods: {
    goBack() {
      if (this.goBackOverride) {
        return this.goBackOverride();
      }
      return this.$router.back();
    }
  }
};
</script>
<style lang="scss" scoped>
.nav-bar {
  z-index: 8;
  min-height: 80px;
  .icon-wrapper {
    height: 21px;
  }
  &.grid {
    margin-bottom: 10px !important;
  }
  &.solid {
    background: var(--color-accent);
  }
}
</style>
