<template>
  <div
    class="button-container"
    :class="[`type-${type}`, `size-${size}`, `color-${buttonColor}`, block ? 'block' : '', inverted ? 'inverted' : '', inline ? 'inline' : '']"
  >
    <a
      v-bind="$attrs"
      class="button"
      :class="{'disabled': disabled, 'align-right' : right}"
      @click="onClick"
    >
      <slot
        v-if="!loading"
        name="content"
      >
        <div
          v-if="icon"
          class="icon-container"
        >
          <n-icon
            :name="icon"
            :color="iconColor"
          />
        </div>
        <n-text
          ellipsis
          :preset="size === 'md' ? 'btn-3' : 'btn-2'"
        >
          <slot />
        </n-text>
      </slot>
      <n-spinner
        v-if="loading"
        :color="iconColor"
      />
    </a>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: "filled",
      validator: (type) =>
        ["filled", "outlined", "outlined white"].includes(type),
    },
    size: {
      type: String,
      default: "md",
      validator: (size) => ["md", "lg", "xl"].includes(size),
    },
    color: {
      type: String,
      default: "default",
      validator: (color) => ["default", "success", "error", "warning", "neutral"].includes(color),
    },
    block: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    buttonColor() {
      return this.disabled ? 'muted' : this.color;
    },
    iconColor() {
      if (this.disabled) {
        if (!this.inverted && this.type == 'filled') {
          return 'white';
        }
        return 'muted';
      }
      if (this.color === 'error' && this.inverted) {
        return this.color;
      }
      if (this.color === 'warning' && this.inverted) {
        return this.color;
      }
      if (this.inverted) {
        return 'accent';
      }
      return 'white';
    },
  },
  watch: {
    disabled(newVal, oldVal) {
      return newVal;
    }
  },
  methods: {
    onClick() {
      if (this.disabled || this.loading) return;
      this.$emit('click');
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.button-container {
  width: 100%;
  &.color-default {
    --btn-fill-color: var(--color-accent);
    --btn-text-color: var(--color-white);
  }

  &.color-blue {
    --btn-fill-color: var(--color-blue);
    --btn-text-color: var(--color-white);
  }

  &.color-success {
    --btn-fill-color: var(--color-green);
    --btn-text-color: var(--color-white);
  }

  &.color-error {
    --btn-fill-color: var(--color-red);
    --btn-text-color: var(--color-white);
  }
  &.color-warning {
    --btn-fill-color: var(--color-orange);
    --btn-text-color: var(--color-white);
  }
  &.color-neutral {
    --btn-fill-color: transparent;
    --btn-text-color: var(--color-grey-darker);

    &.type-outlined {
        --border: var(--color-grey);
    }

  }

  &.size-md {
    --btn-padding: var(--btn-md-padding);
    grid-column: 2 span;
    --height: 34px;
  }
  &.size-lg {
    --btn-padding: var(--btn-lg-padding);
    grid-column: 3 span;
    --height: 48px;
  }
  &.size-xl {
    --btn-padding: var(--btn-lg-padding);
    grid-column: 3 span;
    --height: 55px;
  }

  &.type-filled {
    --fill: var(--btn-fill-color);
    --border: var(--btn-fill-color);
    --text: var(--btn-text-color);
    &.inverted {
      --fill: var(--btn-text-color);
      --border: var(--btn-text-color);
      --text: var(--btn-fill-color);
    }
  }

  &.type-outlined {
    --fill: transparent;
    --border: var(--btn-text-color);
    --text: var(--btn-text-color);
     &.inverted {
      --fill: transparent;
      --border: var(--btn-fill-color);
      --text: var(--btn-fill-color);
    }
    &.white {
      --fill: var(--color-white);
    }
  }

  &.color-muted {
    --fill: var(--color-grey-light);
    --text: var(--color-white);
      &.inverted {
        --text: var(--color-grey-light);
        --border: transparent;
      }
      &.type-outlined {
        --fill: transparent;
        --border: var(--color-grey-light);
        --text: var(--color-grey-light);
      }
  }

  &.inline {
    display: inline-block;
    width: auto;

    .button {
      width: auto;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .button {
    background-color: var(--fill);
    border-color: var(--border);
    color: var(--text);
  }

  &.block {
    grid-column: 1 / -1;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--height);
    border-radius: 9999px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    text-align: center;
    transition: opacity 0.2s ease-in-out,
      background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out,
      color 0.2s ease-in-out;
    user-select: none;
    width: 100%;
    box-sizing: border-box;
    &.align-right {
      justify-content: flex-end;
    }

    &:not(.disabled) {
      &:active {
        opacity: 0.75;
      }
    }
    .icon-container {
      display: inline-block;
      margin-right: 5px;
      fill: var(--text);
    }
  }
}
</style>
