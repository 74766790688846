<template>
  <n-bottom-sheet
    type="header"
    :title="$t('greenTransport.sheet.title')"
    class="sheet"
    dismissible
    :closed="true"
    ref="sheet"
    @dismissed="$emit('dismissed')"
  >
    <n-grid :row-gap="5" :bottom-gap="5">
      <n-text preset="header" block color="accent">{{
        $t('greenTransport.sheet.selectDay')
      }}</n-text>
      <n-text preset="body" block color="grey-dark">{{
        $t('greenTransport.sheet.dayDescription')
      }}</n-text>
      <div class="span-6 flex-start">
        <n-date-picker
          type="date"
          v-model="selectedDay"
          string-format="d. MMMM"
          @input="getCurrentDay"
          class="gutter-margin date"
          :max-datetime="(new Date()).toISOString()"
        />
        <n-spinner v-if="isFetchingData" />
      </div>
      <span class="span-6" v-if="dayRegistered">
        <n-icon name="checkmark" class="inline gutter-margin" color="accent" />
        <n-text color="grey-dark" preset="sub">{{
          $t('greenTransport.sheet.alreadyRegistered')
        }}</n-text>
      </span>
    </n-grid>
    <!--  -->
    <n-grid :row-gap="5">
      <n-text preset="header" block color="accent">{{
        $t('greenTransport.sheet.dailyKm')
      }}</n-text>
      <n-text preset="body" block color="grey-dark">{{
        $t('greenTransport.sheet.kmDescription')
      }}</n-text>
      <n-radio
        name="returnOrSingle"
        id="return"
        class="span-3"
        value="return"
        v-model="singleReturn"
      >
        <n-text color="accent" preset="label-2">{{
          $t('greenTransport.sheet.return')
        }}</n-text>
      </n-radio>
      <n-radio
        name="returnOrSingle"
        id="single"
        class="span-3"
        value="single"
        v-model="singleReturn"
      >
        <n-text color="accent" preset="label-2">{{
          $t('greenTransport.sheet.single')
        }}</n-text>
      </n-radio>
      <n-input
        class="span-6"
        :value="$n(bike)"
        @blur="bike = eToNum($event)"
        :label="$t('greenTransport.sheet.bike')"
      />
      <n-input
        class="span-6"
        :value="$n(walk)"
        @blur="walk = eToNum($event)"
        :label="$t('greenTransport.sheet.walk')"
      />
      <n-input
        class="span-6"
        :value="$n(public)"
        @blur="public = eToNum($event)"
        :label="$t('greenTransport.sheet.public')"
      />
      <n-button @click="save" block size="lg" :loading="isSaving">{{
        $t('greenTransport.sheet.save')
      }}</n-button>
    </n-grid>
  </n-bottom-sheet>
</template>

<script>
import userApi from '@/api/user';
export default {
  data: () => ({
    selectedDay: new Date(),
    singleReturn: 'return',
    isFetchingData: false,
    isSaving: false,
    bike: 0,
    walk: 0,
    public: 0,
    dayRegistered: false,
  }),
  methods: {
    eToNum(e) {
      if (e.target.value === '') {
        return 0;
      }
      return parseFloat(e.target.value.replace(',','.'));
    },
    open() {
      this.$refs.sheet.open();
    },
    async getCurrentDay() {
      this.isFetchingData = true;
      const data = await userApi.getGreenDay(this.selectedDay.toISOString());
      if (data.date != null) {
        this.singleReturn = data.trip_count === 2 ? 'return' : 'single';
        this.bike = data.bike;
        this.public = data.public;
        this.walk = data.walk;
        this.dayRegistered = true;
      } else {
        this.dayRegistered = false;
        this.bike = null;
        this.walk = null;
        this.public = null;
        this.singleReturn = 'return';
      }
      this.isFetchingData = false;
    },
    async save() {
      if (this.isSaving) return;
      this.isSaving = true;
      try {
        await userApi.upsertGreenDay({
          ...this.$data,
          date: this.selectedDay,
          trip_count: this.singleReturn === 'return' ? 2 : 1,
        });
        this.dayRegistered = true;
        this.$success(this.$t('greenTransport.sheet.saved'));
      } catch {
        this.$error(this.$t('error.generic'));
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.gutter-margin {
  margin-right: var(--grid-gutter);
}
.date {
  height: 21px; // Small 'jump' detected when the spinner is running. Maybe because of diagonal height of spinner?
}
</style>

