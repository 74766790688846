<template>
  <div class="view-container">
    <n-nav-bar :title="$t('greenTransport.title')" />
    <n-scrollable>
      <n-layout>
        <n-grid :row-gap="5">
          <n-text class="span-6" preset="header" color="accent">{{
            greenTransport.company_name
          }}</n-text>
          <n-text class="span-6" preset="body" color="grey-dark">
            {{ $t('greenTransport.description') }}
          </n-text>
          <n-button size="lg" block @click="openSheet">{{
            $t('greenTransport.buttonText')
          }}</n-button>
        </n-grid>
        <n-grid :top-gap="5">
          <n-text preset="header" color="accent" class="span-6"
            >{{ $t('greenTransport.report.title')
            }}<n-help inline :text="$t('greenTransport.report.info')"
          /></n-text>
          <n-spinner v-if="isFetchingStats" class="span-6 flex-center" />
          <template v-else>
            <n-text preset="label-2" color="grey-dark" class="span-2">{{
              $t('greenTransport.report.month')
            }}</n-text>
            <n-text preset="label-2" color="accent" class="span-4">{{
              monthly
            }}</n-text>
            <n-text preset="label-2" color="grey-dark" class="span-2">{{
              $t('greenTransport.report.year')
            }}</n-text>
            <n-text preset="label-2" color="accent" class="span-4">{{
              yearly
            }}</n-text>
            <n-text preset="label-2" color="accent" class="span-6">{{
              $t('greenTransport.report.week')
            }}</n-text>
            <n-text
              v-for="(date, index) in stats.week"
              :key="index"
              preset="sub"
              color="grey-dark"
              class="span-6"
            >
              {{
                `${format(date.date, 'dd. MMMM')}: ${$n(date.total_km, 'km')} ${$t(
                  'greenTransport.report.kms'
                )}`
              }}
            </n-text>
          </template>
        </n-grid>
        <n-grid :top-gap="5">
          <n-text preset="header" color="accent" class="span-6">{{
            $t('greenTransport.consent.header')
          }}</n-text>
          <n-text preset="label-2" color="grey-dark" class="span-2"
            >{{ $t('greenTransport.consent.labelCompany') }}:</n-text
          >
          <n-text color="grey-dark" class="span-4" preset="sub">{{
            greenTransport.company_name
          }}</n-text>
        </n-grid>
        <n-grid :bottom-gap="1">
          <n-text preset="label-2" color="grey-dark" class="span-2"
            >{{ $t('greenTransport.consent.labelConsentDate') }}:</n-text
          >
          <n-text color="grey-dark" class="span-4" preset="sub">{{
            $d(new Date(greenTransport.consent_given), 'dateWithTime')
          }}</n-text>
        </n-grid>
        <n-grid :bottom-gap="3">
          <n-text preset="body" color="grey-dark" class="span-6">
            <i18n path="greenTransport.consent.body">
              <template v-slot:termsLink>
                <a
                  class="link"
                  :href="$t('greenTransport.consent.termsLink')"
                  target="_blank"
                  >{{ $t('greenTransport.consent.termsLinkText') }}</a
                >
              </template>
            </i18n>
          </n-text>
        </n-grid>
        <n-button
          @click="showDialog"
          class="span-6"
          type="filled"
          color="error"
          >{{ $t('greenTransport.consent.buttonText') }}</n-button
        >
      </n-layout>
    </n-scrollable>
    <n-dialog />
    <green-transport-sheet ref="greenTransportSheet" @dismissed="fetchData()" />
  </div>
</template>

<script>
import { namespacedTypes as userTypes } from '@/store/modules/user-types';
import GreenTransportSheet from '@/sheets/greenTransportSheet.vue';
import { format } from '@/vendor/date-fns';
import { mapGetters } from 'vuex';
import userApi from '@/api/user';

export default {
  components: { GreenTransportSheet },
  data: () => ({
    isFetchingStats: true,
    stats: {
      month: null,
      year: null,
      week: null,
    },
  }),
  computed: {
    ...mapGetters('user', ['greenTransport']),
    monthly() {
      return `${this.$n(this.stats.month.total_km, 'km')}${this.$t(
        'greenTransport.report.kms'
      )} (${this.stats.month.total_trips} ${this.$t(
        'greenTransport.report.trips'
      )})`;
    },
    yearly() {
      return `${this.$n(this.stats.year.total_km, 'km')}${this.$t(
        'greenTransport.report.kms'
      )} (${this.stats.year.total_trips} ${this.$t(
        'greenTransport.report.trips'
      )})`;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    format,
    showDialog() {
      this.$modal.show('dialog', {
        title: this.$t('greenTransport.withdrawConsentDialog.title'),
        text: this.$t('greenTransport.withdrawConsentDialog.body'),
        cancel: true,
        color: 'error',
        success: {
          handler: this.withdrawConsent,
        },
      });
    },
    async withdrawConsent() {
      await this.$store.dispatch(userTypes.WITHDRAW_COMPANY_CONSENT);
      this.$router.resetToHome();
    },
    async fetchData() {
      this.isFetchingStats = true;
      const data = (await userApi.greenTransportStats()).data;
      this.stats = {
        month: data.month,
        year: data.year,
        week: data.week.sort((a, b) => new Date(b.date) - new Date(a.date)),
      };
      this.isFetchingStats = false;
    },
    openSheet() {
      this.$refs.greenTransportSheet.open();
    },
  },
};
</script>
