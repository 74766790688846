import router, { navigateFromNotification } from '@/router';
import { EventBus } from '@/vendor/events';
import store from '@/store';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';
import { isIosApp, isAndroidApp, isBrowser, sanitizedLocation, getDeviceFingerprint } from './vendor/utils';
import { permission } from '@/constants';
import { sentryException, sentryMessage } from './vendor/sentry';

function removeTrailingSlash(str) {
  return str.replace(/\/+$/, '');
}

export const send = {
  requestOneSignalPlayerId: () => {
    send.askForPermission(permission.PUSH);
    try {
      const result = sendMessage('oneSignalPlayerIdRequest');
      // ios returns an empty promise
      if (!isIosApp()) {
        receive.oneSignalPlayerIdResult(result);
      }
    } catch (error) {
      sentryException(error);
      console.error(error);
    }
  },
  openURL: (url) => {
    if (isIosApp()) {
      const rejseplanen = 'https://www.rejseplanen.dk/webapp/index.html#';
      if (url.includes(rejseplanen)) {
        const parts = url.split(rejseplanen);
        url = `${rejseplanen}${encodeURI(parts[1])}`;
      }

      webkit.messageHandlers.openURL.postMessage(url);
    } else if (isAndroidApp()) {
      nativeObj.openURL(url);
    } else {
      window.location.href = url;
    }
  },
  hideStatusBar: (shouldHide) => sendMessage('hideStatusBar', shouldHide),
  imReady: () => sendMessage('readyForData'),
  askForPermission: (permission) => sendMessage('requestPermission', permission),
  getLocation: () => sendMessage('getLocation'),
  sendEvent: (name) => sendMessage('logAppEvent', name),
  checkPermission: (permission) => sendMessage('checkPermission', permission),
  openAppSettings: () => sendMessage('openAppSettings')
};

function sendMessage(functionHandler, payload = null) {
  if (isBrowser()) {
    return console.log('[native-bridge] emulating native call', { functionHandler, payload });
  }

  if (isIosApp()) {
    if (payload === null) {
      payload = 'empty';
    }

    if (!webkit.messageHandlers[functionHandler]) {
      console.error('[native-bridge] iOS missing the following method on the bridge', { functionHandler, payload })
    }
    return webkit.messageHandlers[functionHandler].postMessage(payload);
  } else if (isAndroidApp()) {

    if (!nativeObj[functionHandler]) {
      console.error('[native-bridge] Android missing the following method on the bridge', { functionHandler, payload })
    }

    if (payload === null) {
      return nativeObj[functionHandler]();
    }

    return nativeObj[functionHandler](payload);
  }
}

/**
 * Can't be inside 'export const receive' because 'arguments' will be from outerscope
 */
export const receive = {
  notification: (msg) => {
    let json;
    try {
      json = JSON.parse(msg);
    } catch (e) {
      sentryException(e, {rawMessage: msg});
      return;
    }

    const identifier = json.route + JSON.stringify(json.params);
    if (identifier === store.state.app.openedNotification) {
      console.warn('[native-brige] Notification already opended', {identifier})
      return;
    }
    store.commit('app/NOTIFICATION_OPENED', identifier);
    EventBus.$emit('notification', json);

    if (!json.route) {
      return;
    }

    navigateFromNotification(json);
  },
  backButton: () => {
    // On hw back button pressed, return if should handle native
    router.go(-1);

    return false;
  },
  oneSignalPlayerIdResult: (id) => {
    if (!id) {
      console.warn('[native-bridge] oneSignalPlayerId is not set', { value: id });
      return;
    }

    store.dispatch('user/REGISTER_DEVICE', id);
  },
  positionChanged: (lat, lng) => {
    if (Number(lat) === 0 || Number(lng) === 0) {
      return;
    }

    EventBus.$emit('position-changed', lat, lng);
  },
  statusbarHeightAndroid: (height) => {
    store.dispatch('app/SET_STATUS_BAR_HEIGHT', height);
  },
  uiEdges: (json) => {
    let edges = null;

    try {
      edges = JSON.parse(json);
    } catch (e) {
      sentryException(e, { raw: json });
      return;
    }

    if (!edges?.top && !edges?.bottom) {
      return;
    }

    store.commit('app/SET_UI_EDGES', edges);
  },
  handleEvent: (url) => {
    if (url.includes('events/')) {
      const eventName = url.split("/").filter(x => x !== "").pop();
      EventBus.$emit(`router-event-${eventName}`);
    }
  },
  /**
   * Examples of links
   *
   * 'null' - android resume from background
   * 'app.nabogo.com/voucher/{data}' = voucher
   * 'app.nabogo.com/redirect/trip?trip_id&departure_stop_id&destination_stop_id' = Rejseplanen
   * @param {string} link
   */
  inboundUrl: (link) => {
    const isBaseUrl = removeTrailingSlash(link) === removeTrailingSlash(process.env.VUE_APP_ROOT_URL);
    const isAndroidAppResumed = isAndroidApp() && router.app.$route.name != 'main.start' && isBaseUrl;

    console.log('inboundUrl:init', { link, isBaseUrl, isAndroidAppResumed });

    if (isAndroidAppResumed) {
      return;
    }

    if (link === null || isBaseUrl) {
      return router.push({ name: 'main.start' });
    }

    // Rejseplanen
    if (link.includes("redirect/trip")) {
      console.log('inboundUrl: includes redirect/trip', link);
      const url = new URL(link);
      const params = new URLSearchParams(url.search);

      if (params.has("trip_id")) {
        console.log('inboundUrl: has tripId', params.get("trip_id"));
        try {
          const driverTripId = parseInt(params.get("trip_id"));
          const departureStopId = parseInt(params.get("departure_stop_id"));
          const destinationStopId = parseInt(params.get("destination_stop_id"));

          return router.push({
            name: 'trip-details-search',
            query: {
              ref: 'rejseplanen',
              driverTripId,
              departureStopId,
              destinationStopId,
            },
          });
        }
        catch {
          Sentry.captureException(new Error(`inboundUrl in bridge got invalid data for trip. Link: ${link}`));
          return router.push({ name: 'content-not-found' });
        }
      }
    }
    // Voucher
    if (link.includes("/voucher/")) {
      const extractVoucher = /voucher\W([A-Za-z0-9#ÆØÅæøå_-]+)/;
      const match = link.match(extractVoucher);

      if (!match) {
        return router.push("/");
      }
      const [, voucher] = [...match];

      store.commit('user/SET_VOUCHER', voucher);

      if (!store.getters[userTypes.GET_TOKEN]) {
        return;
      }

      return router.push({
        name: 'account',
        params: { showVoucherSheet: true }
      });
    }

    // On-demand in-app
    if (link.includes('qr/') && !isBrowser()) {
      try {
        const code = link.match(/qr\/(\d*)/)[1];
        return router.replace({
          name: 'trip-details-search',
          query: { code },
        });
      }
      catch {
        return router.push({ name: 'content-not-found' });
      }
    }

    link = link.split('nabogo.com/').pop()

    return router.push(`/${link}`)
  },
  onLocationResult: (loc) => {
    let location = null;

    try {
      location = JSON.parse(loc);

      if (!location.latitude || !location.longitude) {
        sentryMessage('[native-bridge@onLocationResult]: Location latitude or longitude is null', {rawLocation: loc});
        return;
      }
    } catch (e) {
      sentryException(e, {rawLocation: loc});
      return;
    }

    if (location?.isMock) {
      sentryMessage('[native-bridge@onLocationResult]: Received mock location', {
        raw: loc,
        device: getDeviceFingerprint()
      });

      return;
    }

    if (!location) {
      return;
    }

    store.commit('app/GOT_LOCATION', location);

    const authenticated = !!store.getters[userTypes.GET_TOKEN];

    if (authenticated && router.currentRoute?.name !== 'main.active-trip') {
      store.dispatch('trip/throttledUpdateLocation', sanitizedLocation(location));
    }

    EventBus.$emit('location', location);
  },
  permissionResult: (requestCode, granted) => {
    console.log('permissionResult', requestCode, granted);
  },
  checkPermissionResult: (response) => {
    const { name, granted } = JSON.parse(response);
    EventBus.$emit('checkPermissionResult', { name, granted });
  }
};

export default { send, receive }
