<template>
  <n-bottom-sheet
    ref="sheet"
    type="header"
    :title="$t('tripDetails.driver.sendToAll')"
    class="sheet"
  >
    <n-button
      size="md"
      block
      type="outlined"
      inverted
      @click="multiMessage = $t('tripDetails.driver.multiMessage.5mins')"
    >
      {{ $t('tripDetails.driver.multiMessage.5mins') }}
    </n-button>
    <n-button
      size="md"
      block
      type="outlined"
      inverted
      @click="multiMessage = $t('tripDetails.driver.multiMessage.10mins')"
    >
      {{ $t('tripDetails.driver.multiMessage.10mins') }}
    </n-button>
    <n-button
      size="md"
      block
      type="outlined"
      inverted
      @click="multiMessage = $t('tripDetails.driver.multiMessage.15mins')"
    >
      {{ $t('tripDetails.driver.multiMessage.15mins') }}
    </n-button>

    <n-grid :y-gap="2">
      <n-chat-text-area
        v-model="multiMessage"
        class="span-6 multi-message-textarea"
        :placeholder="$t('tripDetails.driver.multiMessage.placeholder')"
      />
    </n-grid>

    <n-grid
      v-if="!allPassengersWillReceiveMessage"
      :y-gap="0"
    >
      <n-text
        class="span-5"
        color="warning"
        preset="label-2"
      >
        {{ $t('tripDetails.driver.multiMessage.notAllWillReceive.title') }}
      </n-text>
      <n-help
        :text="$t('tripDetails.driver.multiMessage.notAllWillReceive.helpText')"
        :button-text="$t('tripDetails.driver.multiMessage.notAllWillReceive.closeButton')"
      />
      <div
        v-for="passenger in passengersWithMandatoryProps"
        :key="passenger.id"
        class="span-6"
      >
        <div class="passenger-container">
          <profile-picture
            class="passenger-picture"
            :src="passenger.user.image"
          />

          <div class="passenger-info">
            <n-text
              ellipsis
              preset="sub"
              color="grey-dark"
            >
              {{
                passenger.user.display_name
              }}
            </n-text>
          </div>
        </div>
      </div>
    </n-grid>

    <n-button
      size="lg"
      :loading="isSending"
      :disabled="multiMessage.length <= 1"
      block
      class="broadcast-container"
      @click="sendMultiMessage"
    >
      {{ $t('tripDetails.driver.multiMessage.send') }}
    </n-button>
  </n-bottom-sheet>
</template>
<script>
import userApi from '@/api/user';
import ProfilePicture from '@/components/shared/profilePicture';

export default {
  components:  { ProfilePicture },
  props: {
    tripId: {
      type: [String, Number],
      required: true,
    },
    passengers: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    multiMessage: '',
    isSending: false,
  }),
  computed: {
    passengersWithMandatoryProps() {
      return this.passengers.filter(
        (passengerTrip) => passengerTrip.status === 'ACCEPTED' && passengerTrip.user.has_mandatory_properties
      ) || []
    },
    allPassengersWillReceiveMessage() {
      return this.passengers.length > 0 && this.passengers.length === this.passengersWithMandatoryProps.length
    },
  },
  methods: {
    openSheet() {
      this.$refs.sheet.open();
    },
    async sendMultiMessage() {
      this.isSending = true;
      try {
        await userApi.broadcastMessageToPassengers(this.tripId, this.multiMessage);
        this.multiMessage = '';
        this.$refs.sheet.dismiss();
        this.$success(this.$t('tripDetails.driver.multiMessage.messageSent'));
      } finally {
        this.isSending = false;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.broadcast-container {
  padding-bottom: 100px;
}

.passenger-container {
  display: flex;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  flex-direction: row;

  .passenger-picture {
    width: 1.5rem;
    height: 1.5rem;
  }

  .passenger-info {
    flex: 1;
    margin-left: 0.5rem;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .passenger-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.5rem;
  }
}

.multi-message-textarea {
  background: var(--color-grey-light);
}
</style>
