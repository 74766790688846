<template>
  <n-grid :row-gap="3">
    <div class="span-6">
      <n-dropdown
        class="span-3 phone-code-dropdown"
        :options="phoneCountryOptions"
        v-model="phoneCountryCode"
        icon="globe"
      />
      <n-signup-input
        class="span-6"
        ref="phoneInput"
        type="tel"
        v-model="phoneNumber"
        :placeholder="$t('main.start.login.phone')"
      />
    </div>

    <terms
      v-model="termsAccepted"
      id="terms"
      :class="{ wiggle: termsNotAcceptedAnimation }"
    />

    <recaptcha-button
      ref="recaptcha"
      :loading="checkingPhone"
      @click="phoneContinue"
      size="lg"
      block
      >{{ $t('main.start.login.continue') }}</recaptcha-button
    >

    <recaptcha-terms />
  </n-grid>
</template>

<script>
import i18n from '@/i18n';
import store from '@/store';
import userApi from '@/api/user';
import Terms from '@/components/shared/terms';
import RecaptchaTerms from '@/components/shared/recaptchaTerms';
import RecaptchaButton from '@/components/shared/recaptchaButton';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';
import { phoneCountryOptions, getPhoneCountry } from '@/vendor/static-options';

export default {
  name: 'authPhoneAuth',
  components: {
    Terms,
    RecaptchaTerms,
    RecaptchaButton,
  },
  data() {
    return {
      recaptcha: null,
      phoneNumber: null,
      phoneCountryOptions: phoneCountryOptions,
      checkingPhone: false,
      answeringCaptcha: false,
      termsAccepted: false,
      termsNotAcceptedAnimation: false,
      selectedPhoneCountry: null,
    };
  },
  computed: {
    phoneCountryCode: {
      get() {
        return (
          this.selectedPhoneCountry ??
          getPhoneCountry(store.state.user.create.country)
        );
      },
      set(newPhoneCode) {
        this.selectedPhoneCountry = newPhoneCode;
      },
    },
  },
  methods: {
    setPhoneNumber(phoneNumber) {
      this.phoneNumber = phoneNumber;
    },

    enforceTermsAccepted() {
      this.termsNotAcceptedAnimation = false;
      if (!this.termsAccepted) {
        setTimeout(() => (this.termsNotAcceptedAnimation = true), 100);
        return false;
      }
      return true;
    },

    async phoneContinue(recaptcha) {
      if (!this.enforceTermsAccepted() || this.checkingPhone) {
        return;
      }

      const phoneCountryCode = this.phoneCountryCode.id;
      const phoneSubscriberNumber = this.phoneNumber;

      const phone = `${phoneCountryCode}${phoneSubscriberNumber}`;

      this.checkingPhone = true;
      let exists = false;

      try {
        exists = await userApi.checkPhone(phone);
      } catch (e) {
        this.$error({ description: i18n.t(e?.response?.data?.message) });
        this.checkingPhone = false;
        return;
      }

      const storeType = exists
        ? userTypes.REQUEST_PHONE_AUTH_OTP
        : userTypes.REQUEST_PHONE_OTP;

      try {
        await store.dispatch(storeType, { phone, recaptcha });
      } catch (e) {
        this.handleOTPError(e);
        return;
      } finally {
        this.checkingPhone = false;
        this.$refs.recaptcha.reset();
      }

      this.$emit('otp-requested', {
        phoneNumber: phoneSubscriberNumber,
        phoneCountryCode,
        exists,
      });
    },
    handleOTPError(error) {
      if (error?.response?.status == 429) {
        this.$emit('otp-error', i18n.t('error.auth.otp.throttle_error'));
      } else if (error?.response?.status == 403) {
        this.$emit('otp-error', i18n.t('error.blocked.phone'));
      } else if (error?.response?.status == 403) {
        this.$emit('otp-error', i18n.t('error.blocked.phone'));
      } else {
        this.$emit('otp-error');
      }
    },
  },
};
</script>

<style scoped>
.phone-code-dropdown {
  margin-bottom: 0.75rem;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(5deg);
  }
  95% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
#terms#terms.wiggle {
  animation: wiggle 0.75s 2;
}
#terms {
  animation: none;
}
</style>
