<template>
  <modal
    ref="modal"
    :name="name"
    class="dialog"
    :width="width"
    height="auto"
    :pivot-y="0.35"
    :click-to-close="canClickToClose"
    @before-open="beforeOpened"
    @before-close="forceClose"
  >
    <n-theme :type="theme">
      <n-layout
        :spacing-x="false"
        :spacing-y="false"
        class="content"
      >
        <n-text
          preset="header"
          class="row title"
        >
          {{ params.title }}
        </n-text>
        <n-text
          preset="body"
          class="color-grey-dark row message"
        >
          <span v-if="params.text">{{ params.text }}</span>
          <slot v-else />
        </n-text>
      </n-layout>

      <n-layout
        v-if="toggle.enabled"
        :spacing-y="false"
        :spacing-bottom="true"
      >
        <n-switch
          v-model="toggle.toggled"
          class="span-6"
        >
          {{ toggle.text }}
        </n-switch>
      </n-layout>
        
      <n-layout class="content buttons">
        <div v-if="cancel" />

        <n-button
          v-if="cancel"
          :color="cancelButton?.color ?? params.color"
          size="md"
          type="outlined"
          inverted
          @click="cancelAction($event)"
        >
          {{ cancelButton.text }}
        </n-button>
        
        <n-column
          v-else
          :span="2"
        />
        <n-button
          :color="successButton?.color ?? params.color"
          :disabled="toggle.enabled && !toggle.toggled"
          size="md"
          @click="success($event)"
        >
          {{
            successButton.text
          }}
        </n-button>
      </n-layout>
    </n-theme>
  </modal>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: false,
      default: 'dialog',
    },
    fullScreen: {
      type: Boolean,
      default: false,
      validator: (fullScreen) => typeof fullScreen === 'boolean',
    }
  },
  data: () => {
    return {
      params: {},
      successButton: {},
      cancelButton: {},
      width: window.innerWidth - 76 + 'px',
      cancel: true,
      explicitClose: false,
      theme: 'passenger',
      toggle: {
        enabled: false,
        toggled: false,
        text: '',
      }
    };
  },
  computed: {
    canClickToClose() {
      return this.params.clickOutside && (this.params.closable === true || this.cancel);
    },
    open() {
      return this?.$refs.modal?.visible ?? false;
    },
  },
  methods: {
    beforeOpened(event) {
      this.explicitClose = false;
      this.params = event.params || {};

      if (this.params.clickOutside === null) {
        this.params.clickOutside = true;
      }

      this.successButton = this.params.success;
      if (this.params.successButton) {
        this.successButton = this.params.successButton;
      }

      if (!this.successButton.text) {
        this.successButton.text = this.$t('c.dialog.ok');
      }

      if ('cancel' in this.params) {
        this.cancel = this.params.cancel;
      }

      if (this.params.cancelButton) {
        this.cancelButton = this.params.cancelButton;
      } else {
        this.cancelButton = {};
      }

      if (!this.cancelButton.text) {
        this.cancelButton.text = this.$t('c.dialog.cancel');
      }

      if(this.params.theme){
        this.theme = this.params.theme;
      }

      if('toggle' in this.params){
        this.toggle = this.params.toggle;
      } else {
        this.toggle = {
          enabled: false,
          toggled: false,
        };
      }
    },
    success() {
      if (this.toggle.enabled && !this.toggle.toggled) {
        return;
      }
      
      if (this.successButton.handler) {
        this.successButton.handler();
      }

      this.close();
    },
    cancelAction() {
      if (this.cancelButton.handler) {
        this.cancelButton.handler();
      }

      this.close();
    },
    close() {
      this.$modal.hide(this.name);
      this.explicitClose = true;
    },
    /**
     * When clicking on the background it supposed to close.
     * Bug it appears there is a bug in the vuejs modal lib.
     * When change tab between trips and recurring it reappears. This is a workaround.
     */
    forceClose() {
      setTimeout(() => {
        if (!this.explicitClose && (this.params.closable === true || this.cancel)) {
          this.close();
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss">
@import '@/style/styleguide.scss';

.dialog {
  z-index: 9999;
  pointer-events: all;
  .v--modal {
    box-shadow: 0px 3px 6px var(--color-shadow);
    border-radius: 34px;
  }

  .title {
    text-align: center;
  }

  .content {
    padding: 20px;
    justify-items: center;

    & > .row {
      grid-column-start: 1;
      grid-column-end: span col4-start;
    }
    .message {
      margin: 10px 0;
      text-align: center;
      white-space: break-spaces;
    }
    &.buttons {
      padding-top:0;
    }
  }
}
</style>
