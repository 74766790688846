<template>
  <div class="activity-list span-6">
    <transition-group name="fade" tag="span">
      <component
        v-for="element in listElements"
        :is="element.component"
        :key="element.key"
        v-bind="element.data"
        v-on="element.events"
      />
    </transition-group>
  </div>
</template>

<script>
import { compareAsc } from 'date-fns';
import { mapState } from 'vuex';
import { commuteBookingStatus } from '@/constants';
import RequestActivity from '@/components/shared/overview/requestActivity';
import DriverTripActivity from '@/components/shared/overview/driverTripActivity';
import { namespacedTypes as namespacedCommute } from '@/store/modules/commute-types';
// TODO: OnDemandTripActivity refactoring
import OnDemandTripActivity from '@/components/shared/overview/onDemandTripActivity';
import PassengerTripActivity from '@/components/shared/overview/passengerTripActivity';

export default {
  name: 'MainStartOverviewSingle',
  components: {
    RequestActivity,
    DriverTripActivity,
    OnDemandTripActivity,
    PassengerTripActivity,
  },
  data: () => ({
    showHeaderText: true,
    forceShowHome: false,
    showCreateOverlay: false,
    showExtraInfoModal: false,
    isBusy: false,
    streamData: [],
  }),
  computed: {
    ...mapState({
      driverTrips: state => state.commute.overview.driverTrips,
      passengerTrips: state => state.commute.overview.passengerTrips,
      requests: state => state.commute.overview.requests,
      onDemandTrip: state => state.commute.overview.onDemandTrip,
    }),
    sortedActivities() {
      let activities = [];
      for (let r = 0; r < this.requests.length; r++) {
        activities.push({
          type: 'request',
          datetime: this.requests[r].planned_departure,
          activity: this.requests[r],
        });
      }
      for (let p = 0; p < this.passengerTrips.length; p++) {
        activities.push({
          type: 'passenger_trip',
          datetime: this.passengerTrips[p].planned_departure,
          activity: this.passengerTrips[p],
        });
      }
      for (let d = 0; d < this.driverTrips.length; d++) {
        activities.push({
          type: 'driver_trip',
          datetime: this.driverTrips[d].planned_departure,
          activity: this.driverTrips[d],
        });
      }

      activities = activities.sort(
        (a, b) => compareAsc(new Date(a.datetime), new Date(b.datetime)),
      );

      if (this.onDemandTrip) {
        activities.unshift({
          type: 'on_demand_trip',
          datetime: this.onDemandTrip.planned_departure,
          activity: this.onDemandTrip,
        });
      }

      const activeTrip = activities.find((a) => a.type === 'driver_trip' && a.activity.started_at !== null && a.activity.completed_at === null);
      if (activeTrip) {
        activities = activities.filter(activity => activity.activity.id !== activeTrip.activity.id)
        activities.unshift(activeTrip);
      }

      return activities;
    },
     listElements() {
      const elements = [];
      let lastDate = null;
      for (let i = 0; i < this.sortedActivities.length; i++) {
        const activity = this.sortedActivities[i];

        if (activity.type === 'request') {
          elements.push({
            key: `ra-${activity.activity.id}`,
            component: 'request-activity',
            data: {
              request: activity.activity,
            },
          });
        } else if (activity.type === 'passenger_trip') {
          if (!!activity.activity.driver_rating) {
            continue;
          }

          elements.push({
            key: `pta-${activity.activity.id}`,
            component: 'passenger-trip-activity',
            data: {
              passengerTrip: activity.activity,
            },
          });
        } else if (activity.type === 'driver_trip' && this.shouldShowDriverTrip(activity.activity)) {
          elements.push({
            key: `dta-${activity.activity.id}`,
            component: 'driver-trip-activity',
            data: {
              driverTrip: activity.activity,
            },
          });
        } else if (activity.type === 'on_demand_trip') {
          elements.push({
            key: `odt-${activity.activity.id}`,
            component: 'on-demand-trip-activity',
            data: {
              onDemandTrip: activity.activity,
            },
          });
        }
      }
      return elements;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.dispatch(namespacedCommute.UPDATE_OVERVIEW).finally(() => {
        this.isBusy = false;
      });
    },
    shouldShowDriverTrip({ completed_at, passenger_trips }) {
      const isCompleted = completed_at !== null;
      const acceptedPassengers = passenger_trips.filter(p => p.status === commuteBookingStatus.accepted)
      const passengerRated = acceptedPassengers.filter((p) => !!p.passenger_rating)

      if (isCompleted && acceptedPassengers.length == 0) {
        return false
      }

      if (isCompleted && acceptedPassengers.length == passengerRated.length) {
        return false;
      }

      return true;
    },
  }
}
</script>
<style scoped>
.fade-leave-active { transition: opacity 1s; }
.fade-enter, .fade-leave-to { opacity: 0; }
</style>
