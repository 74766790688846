<template>
  <textarea v-bind="$attrs" @input="$emit('input', $event.target.value)" :value="value" :rows="rows"></textarea>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: false
    },
    rows: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

textarea {
  border: 1px solid var(--color-grey);
  border: none;
  min-height: 4rem;
  outline: none;
  border-radius: 1rem;
  padding: 1rem;
  overflow: scroll;
  width: 100%;
  resize: none;
  font-size:   var(--text-md-font-size);
  line-height: var(--text-md-line-height);
  color: var(--color-grey-dark);
  background: transparent;
  &:focus {
    color: var(--color-grey-darker);
  }
  &::placeholder {
    color: var(--color-grey-dark);
  }
}

</style>
