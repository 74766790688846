<template>
  <div>
    <n-grid>
      <n-text preset="label-2" class="flex-v-center" color="grey-dark">{{
        $t('c.tripOptions.from')
      }}</n-text
      ><n-text class="span-5" color="grey-dark">{{ from }}</n-text>
      <n-text preset="label-2" class="flex-v-center" color="grey-dark">{{
        $t('c.tripOptions.to')
      }}</n-text
      ><n-text class="span-5" color="grey-dark">{{ to }}</n-text>
    </n-grid>
    <n-grid :y-gap="5">
      <n-text class="span-6" preset="sub" color="grey-dark">{{
        theme === 'driver'
          ? $t('c.tripOptions.descriptionDriver')
          : $t('c.tripOptions.descriptionPassenger')
      }}</n-text>
    </n-grid>
    <n-grid :bottom-gap="7" :row-gap="3">
      <n-column :span="6" v-if="isDriver">
        <seat-selector v-model="selectedSeats" :min="minSeats" />
      </n-column>
      <n-column :span="6" v-if="isDriver">
        <charge-for-rides-selector v-model="chargeForTrip" />
      </n-column>
      <n-text preset="label-2" color="grey-dark">
        {{ $t('c.tripOptions.departure') }}</n-text
      >
      <template v-for="(day, i) in selectedTimes">
        <n-theme
          :type="day.checked ? theme : 'deactivated'"
          class="span-6"
          :key="i"
        >
          <n-grid>
            <n-text preset="label-2" class="span-4" color="accent">{{
              day.text
            }}</n-text>
            <n-checkbox v-model="day.checked" class="day-checkbox" />
            <n-date-picker
              :value="day.checked ? day.date : null"
              @input="onTimeChanged(day, $event)"
              string-format="HH:mm"
              type="time"
              placeholder="--:--"
            />
          </n-grid>
        </n-theme>
      </template>
    </n-grid>
  </div>
</template>
<script>
import SeatSelector from '@/components/shared/seatSelector';
import ChargeForRidesSelector from '@/components/shared/chargeForRidesSelector.vue';
export default {
  components: {
    SeatSelector,
    ChargeForRidesSelector,
  },
  props: {
    value: {
      type: Object,
    },
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    defaultTime: {
      type: Date,
      default: () => new Date(new Date().setHours(9, 0)), // 2 x new, since setHours return since epoch, the wrap gives date object
    },
    theme: {
      type: String,
      default: 'driver',
    },
    isDriver: {
      type: Boolean,
      default: false,
    },
    minSeats: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      weekdayNames: [
        this.$t('days.monday'),
        this.$t('days.tuesday'),
        this.$t('days.wednesday'),
        this.$t('days.thursday'),
        this.$t('days.friday'),
        this.$t('days.saturday'),
        this.$t('days.sunday'),
      ],
      weekdayIds: [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ],
      selectedTimes: [],
      isEdited: false,
      selectedSeats: this.value.seats,
      chargeForTrip: this.value.chargeForTrip,
    };
  },
  watch: {
    selectedTimes: {
      deep: true,
      handler() {
        this.updated();
      },
    },
    selectedSeats() {
      this.updated();
    },
    chargeForTrip() {
      this.updated();
    },
  },
  mounted() {
    this.setTimes();
    if (this.selectedTimes.length === 0 && this.defaultTime) {
      this.selectedTimes = Array(7)
        .fill(null)
        .map((_, i) => {
          return {
            id: this.weekdayIds[i],
            text: this.weekdayNames[i],
            checked: i <= 4, // check mon - fri
            date: this.defaultTime ? this.defaultTime : null,
          };
        });
    }
    // The above code triggers the below onTimeChanged which sets the isEdited to true
    // This just sets it back to keep track if the user manually changed something
    this.$nextTick(() => {
      this.isEdited = false;
    });
  },
  methods: {
    /**
     * Set time objects from prop times
     */
    setTimes() {
      if (!this.value.times) {
        this.selectedTimes = [];
        return;
      }
      const times = this.value.times[0];
      if (times.id) {
        // Already correct formatted array (Maybe only for hot reload?)
        this.selectedTimes = this.value.times;
        return;
      }
      const timesArray = [];
      this.weekdayIds.forEach((x, i) => {
        const hours = times[x] ? times[x].substring(0, 2) : null;
        const mins = times[x] ? times[x].substring(3, 5) : null;
        timesArray.push({
          id: x,
          text: this.weekdayNames[i],
          checked: times[x] != null,
          date:
            times[x] != null
              ? new Date(new Date().setHours(hours, mins))
              : null,
        });
      });
      this.selectedTimes = timesArray;
    },
    onTimeChanged(day, date) {
      // if monday and first change, change them all
      if (!this.isEdited && day.text === this.weekdayNames[0]) {
        this.selectedTimes.forEach((x) => {
          x.date = date;
        });
      } else {
        if (date != null) {
          day.date = date;
          day.checked = true;
          this.isEdited = true;
        }
      }
    },
    updated() {
      this.$emit('input', {
        times: this.selectedTimes.filter((x) => x.checked),
        seats: this.selectedSeats,
        chargeForTrip: this.chargeForTrip,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.day-checkbox {
  margin-right: 10px;
}
</style>
