<template>
  <div class="select-wrapper" :class="{'disabled': disabled}" >
    <div class="inner-wrapper" :style="{ maxWidth: width }">
      <n-icon v-if="icon" :name="icon" size="sm" color="accent" class="leftIcon" />
      <div class="selectedOption">
        <n-text preset="sub" v-if="value">{{value.text}}</n-text>
        <n-text preset="sub" v-if="!value && placeholder">{{ placeholder }}</n-text>
      </div>
      <select @change="onChange($event)" ref="select" :style="{paddingLeft: icon ? '17px' : 0}" :disabled="disabled">
        <option v-if="placeholder"> {{ placeholder }} </option>
        <option v-for="option in options" :key="option.id" :value="option.id" :selected="value && value.id === option.id">{{option.text}}</option>
      </select>
      <n-icon name="chevron-down" outline size="sm" color="accent" />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      required: true,
    },
    icon: {
      type: String,
    },
    value: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      width: '0px',
    }
  },
  mounted() {
    this.selected = this.value;
    this.setWidths();
  },
  methods: {
    setWidths() {
      this.width = `${this.$refs.select.clientWidth}px`;
    },
    onChange(e) {
      const item = this.options.find(x => x.id == e.target.value);
      this.$emit('input', item);
    }
  },
};
</script>


<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.select-wrapper {
  --color: var(--color-accent);
  height: 17px;
  position: relative;
  display: flex;
  &.disabled {
    --color: var(--color-grey);
  }
  .inner-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .selectedOption {
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    color: var(--color);
    margin: 0 5px 0 0;
  }
  svg {
    fill: var(--color);
    stroke: var(--color);
  }
  select {
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 17px 0 0;
    opacity: 0;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance: none;
    border: none;
    &:focus {
      outline: none;
    }
  }
  .leftIcon {
    margin-right: 5px;
  }
}
</style>
