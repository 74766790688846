import Vue from 'vue';
import router from '@/router';
import initializeSentry from '@/vendor/sentry';

initializeSentry(Vue, router);

import VueSVGIcon from 'vue-svgicon';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueDatetime from '@/vendor/vue-datetime';
import VModal from 'vue-js-modal';
import VueScrollTo from 'vue-scrollto';
import { camelCaseToKebabCase } from '@/vendor/utils';
import nativeBridge from './native-bridge';
import { isBrowser, getReleaseVersion } from '@/vendor/utils';

// You need a specific loader for CSS files
import '@/vendor/vue-datetime/vue-datetime.css';

import '@/icons';
import App from '@/App';
import store from '@/store';

import i18n from '@/i18n';
import { mapGetters } from 'vuex';

import Alert from '@/plugins/Alert';
import './registerServiceWorker';
import { SWHelper, scheduleSWUpdate } from './serviceWorkerHelper';
import { isMinimumNativeVersion } from './vendor/utils';
import { initFormbricks } from '@/plugins/Formbricks';
import { browserLang } from "@/i18n";
import { namespacedTypes as userTypes, } from "@/store/modules/user-types";

/* eslint-disable */
// Native methods
window.native = nativeBridge.receive;
window.sendNative = nativeBridge.send;

let locale = 'en';
if (store.state.user.profile.language) {
  if (typeof store.state.user.profile.language === 'object') {
    locale = store.state.user.profile.language.id;
  } else {
    locale = store.state.user.profile.language;
  }
}

if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false;
  Vue.config.productionTip = false;
}

Vue.use(Alert);
Vue.use(VueDatetime);
Vue.use(VModal);
Vue.use(VueScrollTo);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,
    libraries: 'geometry',
    v: '3.55',
  },
  autobindAllEvents: true,
});

Vue.use(VueSVGIcon, {
  tagName: 'svg-icon',
})

/**
 * Google Tag Manager (GTM)
 */

if (isBrowser() && process.env.VUE_APP_GTM_ID) {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer',process.env.VUE_APP_GTM_ID);
}

/**
 * Global components
 */

const requireContext = require.context('./components/core', true, /.*\.vue$/);

requireContext.keys().forEach(file => {
  const component = requireContext(file).default;
  const fileName = file
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');

  Vue.component('n-' + camelCaseToKebabCase(fileName), component);
});

/* eslint-disable no-new */
window.vueApp = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  watch: {
    $route(route) {
      store.commit('app/CLOSE_MENU');
      if (route.name && !route.name.startsWith('auth')) {
        // Don't ping between auth pages
        store.dispatch('user/PING_FETCH_PROFILE');
      }
    },
  },
  async created() {
    console.log('[main.js] VUE_APP_VERSION', getReleaseVersion());

    store.commit('app/CLOSE_MENU');
    store.commit('app/SET_FINGERPRINT');

    if (!isBrowser() && !isMinimumNativeVersion()) {
      store.commit('app/SET_NATIVE_UPDATE_AVAILABLE', true);
    }

    if (store.state.app.isUpdateAvailable) {
      await SWHelper.skipWaiting();
      store.commit('app/SET_UPDATE_AVAILABLE', false);
      console.log('[SW]: VUE_APP_VERSION', getReleaseVersion());
    }
  },
  mounted() {
    nativeBridge.send.imReady(getReleaseVersion());

    const lang = this.isLoggedIn()
      ? store.state.user.profile.language
      : browserLang;

    store.commit(userTypes.SELECT_LANGUAGE, {
      lang,
      withDefaults: true,
    });

    store.dispatch('user/PING_FETCH_PROFILE');

    // Only get OneSignal Player ID when logged in.
    if (store.getters['user/isLoggedIn']) {
      nativeBridge.send.requestOneSignalPlayerId();

      initFormbricks();
    }

    if (typeof window.navigator.onLine === 'boolean') {
      store.commit('app/SET_ONLINE', window.navigator.onLine);
    }

    window.addEventListener(
      'online',
      () => store.commit('app/SET_ONLINE', true)
    );
    window.addEventListener(
      'offline',
      () => store.commit('app/SET_ONLINE', false)
    );

    localStorage.setItem('sessionStartTime', new Date().toString());
    
    scheduleSWUpdate();
  },
  methods: {
    ...mapGetters('user', ['isLoggedIn']),
  },
  render: (h) => h(App),
});

// allow active on safari
document.addEventListener('touchstart', () => {}, true);
