<template>
  <span>
    <n-text preset="label-2" color="grey-dark" v-if="withLabel">
      {{ $t('main.searchHeader.seats') }}
    </n-text>

    <n-dropdown
      :icon="withIcon ? 'person' : ''"
      v-model="selectedSeats"
      :options="seatOptions"
    />
  </span>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      required: false,
    },
    withLabel: {
      type: Boolean,
      default: true,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 4,
    },
  },
  data() {
    const offSet = this.min - 1;
    const seatIndex = this.value ? this.value - 1 - offSet : 0;
    const availableSeats = Array(this.max - offSet)
      .fill(null)
      .map((_, i) => {
        return {
          id: i + 1 + offSet,
          text: `${this.$tc('c.searchHeader.seats', i + 1 + offSet)}`,
        };
      });
    this.$emit('input', availableSeats[seatIndex].id);
    return {
      seatOptions: availableSeats,
      selectedSeats: availableSeats[seatIndex],
    };
  },
  watch: {
    selectedSeats() {
      this.$emit('input', this.selectedSeats.id);
    },
  },
};
</script>
