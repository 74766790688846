<template>
  <n-theme type="passenger" class="view-container">
    <n-nav-bar :title="$t('main.demand.tripOptions.title')" solid />
    <n-scrollable>
      <n-layout>
        <trip-options
          theme="passenger"
          class="span-6"
          :from="formattedFrom"
          :to="formattedTo"
          v-model="options"
          :default-time="time"
        />
        <n-button @click="create" size="lg" :loading="creating" block>{{
          $t('main.demand.tripOptions.create')
        }}</n-button>
      </n-layout>
    </n-scrollable>

    <n-date-picker
      v-show="pickingReturnTime"
      ref="datepicker"
      :auto="false"
      :title="$t('main.demand.selectReturnTime')"
      @input="onReturnTripTimeChanged"
      :value="defaultReturnTripTime"
      string-format="HH:mm"
      type="time"
      @close="onDontCreateReturnTrip"
    />
    <request-return-trip-dialog ref="requestReturnTripDialog" />
    <n-dialog />
  </n-theme>
</template>

<script>
import constants from '@/constants';
import * as util from '@/vendor/utils';
import commuteApi from '@/api/commute';
import { EventBus } from '@/vendor/events';
import { format } from '@/vendor/date-fns';
import { set } from 'date-fns';
import TripOptions from '@/components/shared/tripOptions';
import RequestReturnTripDialog from '@/dialogs/requestReturnTripDialog';

export default {
  name: 'mainDemandCreateDemandOptions',
  components: {
    TripOptions,
    RequestReturnTripDialog,
  },
  props: {
    trip: {
      type: Object,
      required: true,
    },
    time: {},
    from: {
      type: Object,
      required: false,
    },
    to: {
      type: Object,
      required: false,
    },
    return: {
      type: Boolean,
      default: false,
    },
    times: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      creating: false,
      pickingReturnTime: false,
      options: {},
    };
  },
  computed: {
    formattedFrom() {
      return this.trip.from_stop_id && this.from.stop ?
        this.from.completeAddress :
        this.trip.from_street;
    },
    formattedTo() {
      return this.trip.to_stop_id && this.to.stop ?
        this.to.completeAddress :
        this.trip.to_street;
    },
    defaultReturnTripTime() {
      return set(new Date(), constants.demandDefaultReturnTime);
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.trip) {
      next({ name: 'main.demand' });
      return;
    }

    next((vm) => {
      if (Array.isArray(vm.times)) {
        vm.options.times = vm.times;
      }
    });
  },
  methods: {
    create() {
      this.creating = true;
      const recurringTripData = {
        ...this.trip,
        ...util.parseRecurringOptions(this.options),
        type: 'OTHER',
      };
      commuteApi.postPassengerRequest(recurringTripData).then(() => {
        this.onSavedSuccessfully();
      });
    },
    onSavedSuccessfully() {
      this.creating = false;
      EventBus.$emit('fetch-recurring-overview');

      if (this.$route.name === 'demand.options.return') {
        this.onDontCreateReturnTrip(true);
        return;
      }

      this.$refs.requestReturnTripDialog.show(
        // yes
        () => {
          // for visual cue only
          setTimeout(() => {
            this.pickingReturnTime = true;
            this.$refs.datepicker.open();
          }, 200);
        },

        // no
        () => {
          this.onDontCreateReturnTrip(true);
        }
      );
    },
    getDaysWithDefaultTime(hours, minutes, seconds = 0) {
      if (!this.options.times) {
        return [];
      }

      const times = {};

      this.options.times.forEach((time) => {
        const date = set(time.date ? time.date : new Date(), { hours, minutes, seconds });

        times[time.id] = format(date, 'HH:mm');
      });

      return times;
    },
    onCreateReturnTrip(date) {
      this.$router.resetToHome();

      this.$nextTick(() => {
        this.$router.push({
          name: 'demand.options.return',
          params: {
            trip: {
              ...this.trip,
              ...util.swapAddressData(this.trip),
            },
            from: this.to,
            to: this.from,
            times: [
              this.getDaysWithDefaultTime(date.getHours(), date.getMinutes()),
            ],
          },
        });
      });
    },
    onReturnTripTimeChanged(date) {
      if (!(date instanceof Date) || !this.pickingReturnTime) {
        return;
      }

      this.pickingReturnTime = false;
      this.onCreateReturnTrip(date);
    },
    onDontCreateReturnTrip(showSuccessSnack = false) {
      this.$router.resetToHome();
      if (showSuccessSnack) {
        this.$success(this.$t('main.demand.recurringTripCreated'));
      }

    },
  },
};
</script>
