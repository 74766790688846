<template>
  <span>
    <n-text preset="label-2" color="grey-dark">
      {{ $t('main.provide.dropdownCharge.header') }}
      <n-help :text="helpText" />
    </n-text>
    <n-dropdown v-model="selectedOption" :options="options" />
  </span>
</template>

<script>
import i18n from '@/i18n';
import { mapState } from 'vuex';
import { namespace as userNamespace } from '@/store/modules/user-types';

const options = [
  { id: 'true', text: i18n.t('main.provide.dropdownCharge.positive') },
  { id: 'false', text: i18n.t('main.provide.dropdownCharge.negative') },
];

export default {
  props: {
    value: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapState(userNamespace, ['profile']),
    helpText() {
      return i18n.t("main.provide.dropdownCharge.helpModal");
    }
  },
  data() {
    let selectedOption = options.find((x) => x.id == 'true');
    if (this.value == false) {
      selectedOption = options.find((x) => x.id == 'false');
    }
    return {
      options,
      selectedOption,
    };
  },
  watch: {
    selectedOption() {
      this.$emit('input', this.selectedOption.id === 'true');
    },
  },
};
</script>
