<template>
  <n-bottom-sheet class="sheet" ref="sheet" :start="bottom" no-layout :pad-bottom="false">
    <n-grid v-if="period && hasResults" :y-gap="2" class="text-center">
      <div class="span-6">
        <n-text preset="body" color="grey-dark">
          {{
            $t('marketplace.showingDepartures', {
              from: formatTime(period.start),
              to: formatTime(period.end)
            })
          }}
        </n-text>
      </div>
    </n-grid>
    <n-grid v-if="loading" :y-gap="10">
      <n-column :span="6">
        <n-spinner class="span-6 flex-center self-top" />
      </n-column>
    </n-grid>
    <n-layout :spacing-y="false" :spacing-bottom="true">
      <div class="span-6" v-if="!loading && !hasResults">
        <n-layout>
          <n-text class="span-6 pre-line" preset="sub" color="grey-dark" align="center">{{ $t('marketplace.noResults') }}</n-text>
        </n-layout>
      </div>
      <marketplace-nabogo-trip v-for="trip in trips" :trip="trip" :key="trip.id" :seperator="!isLastTrip(trip)" @select="goToTrip(trip)" />
    </n-layout>
    <slot />
  </n-bottom-sheet>
</template>
<script>
import store from "@/store";
import { EventBus } from '@/vendor/events';
import { format } from '@/vendor/date-fns';
import MarketplaceNabogoTrip from '@/screens/main/demand/marketplaceNabogoTrip';
import { namespace as namespaceCommute, namespacedTypes as namespacedCommute } from '@/store/modules/commute-types';
export default {
  name: 'mainDemandMarketplaceSheet',
  components: { MarketplaceNabogoTrip },
  props: {
    trips: {
      type: Array,
      default: () => [],
    },
    streamed: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    searchParams: {
      type: Object,
    },
    period: {
      type: Object,
      default: null,
      required: false,
    },
    shouldTriggerSurvey: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    bottom() {
      return window.innerHeight - 200;
    },
    isAuthenticated() {
      return store.getters['user/isLoggedIn'];
    },
    hasResults() {
      return this.trips.length > 0;
    },
  },
  methods: {
    goToTrip(trip) {
      let params = this.searchParams;
      if (!params.toAddress) {
        const arrival = trip.driver_trip_details.arrival_stop.stop;
        params.toAddress = arrival;
        params.toAddress.completeAddress = arrival.name;
      }
      if (this.isAuthenticated) {
        this.navigate(trip, params);
      } else {
        EventBus.$emit('show-login-sheet', {doAfterLogin: () => { this.navigate(trip, params)} });
      }
    },
    isLastTrip(trip) {
      return this.trips.indexOf(trip) === this.trips.length - 1;
    },
    formatTime(time) {
      return format(time, 'HH:mm');
    },
    navigate(trip, params) {
      this.$store.commit(namespacedCommute.TRIP_DETAILS, { trip, ...params });
      this.$router.push({
        name: 'trip-details-search',
        query: {
          isPublic: trip.public,
          driverTripId: trip.id,
          departureStopPivotId: trip.departure_stop_id,
          destinationStopPivotId: trip.arrival_stop_id
        },
        params: {
          'search-result': true,
          'should-trigger-survey': this.shouldTriggerSurvey
        }
      });
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
