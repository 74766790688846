<script>
export default {
  render() {
    return this.$slots.default
  },
  methods: {
    show(successHandler, cancelHandler) {
      this.$modal.show('dialog', {
        title: this.$t('dialog.requestReturnTripDialog.title'),
        text: this.$t('dialog.requestReturnTripDialog.description'),
        successButton: {
          text: this.$t('dialog.requestReturnTripDialog.yes'),
          handler: () => successHandler(),
        },
        cancelButton: {
          text: this.$t('dialog.requestReturnTripDialog.no'),
          handler: () => cancelHandler(),
        },
        cancel: true,
        clickOutside: false,
      });
    },
  },
}
</script>
