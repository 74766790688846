<template>
  <n-theme :type="theme" class="view-container">
    <n-nav-bar :title="$t('editRecurring.title')" solid />
    <n-scrollable>
      <n-layout>
        <trip-options
          class="span-6"
          :from="recurringTrip.from_street"
          :to="recurringTrip.to_street"
          v-model="options"
          :theme="theme"
          :isDriver="isDriver"
          :minSeats="recurringTrip.seats"
        />
        <n-grid :top-gap="5">
          <n-grid :bottom-gap="2">
            <n-text
              v-if="activeVacation"
              class="span-6"
              preset="sub"
              color="warning"
            >
              {{
                $t('editRecurring.activeVacationDescription', {
                  fromDate: $d(new Date(activeVacation.start), 'dateWithYear'),
                  toDate: $d(new Date(activeVacation.end), 'dateWithYear'),
                })
              }}
            </n-text>
            <n-button
              class="span-6"
              @click="$router.push({ name: 'vacation' })"
              type="outlined"
              inverted
              >{{ $t('editRecurring.goToVacationSettings') }}</n-button
            >
          </n-grid>
          <n-button
            @click="deleteTrip"
            :loading="deleting"
            size="lg"
            type="outlined"
            inverted
            color="error"
            >{{ $t('editRecurring.delete') }}</n-button
          >
          <n-button @click="saveTrip" :loading="saving" size="lg">{{
            $t('editRecurring.save')
          }}</n-button>
        </n-grid>
      </n-layout>
    </n-scrollable>
    <n-dialog />
  </n-theme>
</template>
<script>
import commuteApi from '@/api/commute';
import * as util from '@/vendor/utils';
import constants from '@/constants';
import TripOptions from '@/components/shared/tripOptions';
import { mapActions, mapGetters } from 'vuex';
import { namespacedTypes as commuteTypes } from '@/store/modules/commute-types';
import i18n from '@/i18n/index';

export default {
  components: {
    TripOptions,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    recurringTrip: {
      type: Object,
      default: null,
    },
  },
  data() {
    const times = [
      constants.weekdays.reduce((acc, day) => {
        acc[day] = this.recurringTrip[`${day}_leave`];
        return acc;
      }, {}),
    ];
    return {
      saving: false,
      loading: true,
      deleting: false,
      trip: null,
      manuelOffer: !this.recurringTrip.auto_offer,
      options: {
        times: times,
        seats: this.recurringTrip.seats,
        chargeForTrip: this.recurringTrip.charge_for_trip,
      },
    };
  },
  computed: {
    isDriver() {
      return this.recurringTrip.role === 'DRIVER';
    },
    theme() {
      if (!this.recurringTrip) {
        return 'driver';
      }
      return this.recurringTrip.role.toLowerCase();
    },
    ...mapGetters('user', ['activeVacation']),
  },
  async beforeRouteEnter(to, from, next) {
    const { id, recurringTrip } = to.params;
    if (recurringTrip == null) {
      try {
        to.params.recurringTrip = await commuteApi.fetchRecurringTrip(id);
        next();
      } catch (error) {
        console.error(error);
        next('/content-not-found');
      }
    } else {
      next();
    }
  },
  async mounted() {
    this.loading = false;
  },
  methods: {
    ...mapActions({
      delete: commuteTypes.DELETE_RECURRING,
    }),
    async saveTrip() {
      if (!this.options.times.filter((x) => x.checked).every((x) => !!x.date)) {
        this.$error(i18n.t('editRecurring.missingTime'));
        return;
      }
      this.saving = true;
      await this.$store.dispatch(commuteTypes.UPDATE_RECURRING, {
        ...util.parseRecurringOptions(this.options),
        id: this.recurringTrip.id,
        auto_offer: this.manuelOffer ? 0 : 1,
        seats: this.isDriver ? this.options.seats : 1,
        charge_for_trip: this.options.chargeForTrip,
      });
      this.saving = false;
      this.$success(i18n.t('editRecurring.saved'));
      this.$router.resetToHome();
    },
    deleteTrip() {
      const title = this.isDriver
        ? 'overview.recurring.deleteDialog.titleDriver'
        : 'overview.recurring.deleteDialog.titlePassenger';
      const text = this.isDriver
        ? 'overview.recurring.deleteDialog.descriptionDriver'
        : 'overview.recurring.deleteDialog.descriptionPassenger';
      const snackbar = this.isDriver
        ? 'overview.recurring.deleteDialog.snackbarDriver'
        : 'overview.recurring.deleteDialog.snackbarPassenger';
      this.$modal.show('dialog', {
        title: this.$t(title),
        text: this.$t(text),
        cancel: true,
        color: 'error',
        success: {
          text: this.$t('overview.recurring.deleteDialog.successButton'),
          handler: () => {
            this.deleting = true;
            this.delete(this.recurringTrip)
              .then(() => {
                this.$success(i18n.t(snackbar));
                this.$router.resetToHome();
              })
              .finally(() => {
                this.deleting = false;
              });
          },
        },
      });
    },
  },
};
</script>
