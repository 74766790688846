/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_arrows-swap': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="ico 35 swap"><path pid="0" data-name="Path 10" d="M16.6 17.4l-.83-.84-3.17 3.17v-6.94h-1.19v6.94l-3.18-3.17-.83.84L12 22z" _fill="#b7b7b7"/><path pid="1" data-name="Path 10-2" d="M7.4 6.6l.83.84 3.17-3.17v6.94h1.19V4.27l3.18 3.17.83-.84L12 2z"/></g>'
  }
})
