import {
  format as dateFormat,
  formatDistanceStrict as formatDistanceStrictFormat,
  formatRelative as dateFormatRelative,
  formatDistanceToNow as dateFormatDistanceToNow,
} from 'date-fns';
import daLocale from 'date-fns/locale/da';
import enLocale from 'date-fns/locale/en-GB';
import seLocale from 'date-fns/locale/sv';
import nlLocale from 'date-fns/locale/nl';
import nbLocale from 'date-fns/locale/nb';
import store from '@/store';
import i18n from '@/i18n/index'

function getOptions(options) {
  let locale = enLocale;
  if (store.state.user.profile.language === 'DA') {
    locale = daLocale;
  }
  if (store.state.user.profile.language === 'SV') {
    locale = seLocale;
  }
  if (store.state.user.profile.language === 'NL') {
    locale = nlLocale;
  }
  if (store.state.user.profile.language === 'NB') {
    locale = nbLocale;
  }

  if (typeof options === 'undefined') {
    return { locale };
  }
  return { ...options, locale };
}

export function format(date, formatString, options) {
  if (typeof formatString === 'undefined') {
    formatString = 'yyyy-MM-dd HH:mm:ss';
  }

  // fix iOS specific error for dates formatted as '2019-03-19 13:50:20', which needs to
  // be formatted as '2019-03-19T13:50:20' for iOS to parse date correctly
  if (typeof date === 'string' && date.match(/\d{4}-\d{2}-\d{2}\ \d{2}:\d{2}:\d{2}/gm)) {
    const parts = date.split(' ');
    date = `${parts[0]}T${parts[1]}`;
  }

  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  return dateFormat(date, formatString, getOptions(options));
}

export function formatDistanceStrict(date1, date2) {
  return formatDistanceStrictFormat(date1, date2, getOptions());
}

export function formatRelative(date, base) {
  const baseDate = !base ? new Date() : base;
  return dateFormatRelative(date, baseDate, getOptions());
}

export function formatRelativeWithoutTime(date, base) {
  const fallbackFormat = 'dd. LLLL';

  const options = getOptions();
  const locale = options.locale;

  const relativeModified = {
    lastWeek: fallbackFormat,
    yesterday: `'${i18n.t('datetime.yesterday')}'`,
    today: `'${i18n.t('datetime.today')}'`,
    tomorrow: `'${i18n.t('datetime.tomorrow')}' dd. LLL`,
    // nextWeek: `'${i18n.t('datetime.next')}' eeee`,
    nextWeek: fallbackFormat,
    // other: 'dd.MM.yyyy',
    other: fallbackFormat,
  };

  options.locale = {
    ...locale,
    formatRelative: (token) => relativeModified[token],
  };

  const baseDate = !base ? new Date() : base;
  return dateFormatRelative(new Date(date), baseDate, options);
}

export function formatDistanceToNow(date, options) {
  return dateFormatDistanceToNow(date, getOptions(options));
}
