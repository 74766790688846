<template>
  <div class="wrapper span-6" @click="$refs.input.focus()">
    <div class="inner-wrapper">
      <n-text preset="sub" class="color-grey-dark">{{ label }}</n-text>
      <div class="input">
        <textarea
          ref="input"
          v-bind="$attrs"
          v-on="$listeners"
          @input="$emit('update', $event.target.value)"
          @focus="focused = true"
          @blur="focused = false"
          @keydown="blockNewline"
          :value="value"
          :style="computedStyles"
          ></textarea>
        <n-icon name="pencil" :outline="!focused" />
      </div>
    </div>
    <n-seperator />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update',
    prop: 'value',
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false
    },
    autosize: {
      type: Boolean,
      default: true
    },
    minHeight: {
      type: [Number],
      'default': null
    },
    maxHeight: {
      type: [Number],
      'default': null
    },
  },
  data: () => ({
    focused: false,
    maxHeightScroll: false,
    height: 'auto'
  }),
  computed: {
    computedStyles () {
      if (!this.autosize) return {}
      return {
        resize: 'none',
        height: this.height,
        overflow: this.maxHeightScroll ? 'auto' : 'hidden',
      }
    },
  },
  watch: {
    value(){
      this.$nextTick(this.resize)
    },
  },
  methods: {
    resize () {
      this.height = `auto`
      this.$nextTick(() => {
        let contentHeight = this.$refs.input.scrollHeight + 1
        if (this.minHeight) {
          contentHeight = contentHeight < this.minHeight ? this.minHeight : contentHeight
        }
        if (this.maxHeight) {
          if (contentHeight > this.maxHeight) {
            contentHeight = this.maxHeight
            this.maxHeightScroll = true
          } else {
            this.maxHeightScroll = false
          }
        }
        this.height = contentHeight + 'px';
      })
      return this
    },
    blockNewline(event) {
      const ENTER_KEY = 13
      if (event.keyCode == ENTER_KEY) {
        event.preventDefault();
      }
    }
  },
  mounted() {
    this.resize()
  }
};
</script>

<style scoped>
.input {
  display: flex;
}
textarea {
  border: none;
  flex: 1;
  resize: none;
  letter-spacing: var(--letter-spacing-sm);
  font-size: var(--text-sm-font-size);
  line-height: var(--text-sm-line-height);
  color: var(--color-grey-darker);
}

textarea::placeholder {
  color: var(--color-grey-dark);
}
</style>
