<template>
  <div class="wrapper span-6" @click="$refs.input.focus()">
    <div class="inner-wrapper">
      <n-text preset="sub" class="color-grey-dark">{{ label }}</n-text>
      <div class="input">
        <input v-on="$listeners" v-bind="$attrs" :value="value" @input="onInput($event.target.value)" ref="input" @focus="focused = true" @blur="focused = false" :readonly="readonly" />
        <n-icon v-if="icon" :name="icon" :outline="!focused" />
      </div>
    </div>
    <n-seperator />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update',
    prop: 'value',
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
    }
  },
  computed: {
    icon() {
      let icon = null;
      if (this.value || (this.focused && !this.readonly)) {
        icon = 'pencil';
      }
      return icon;
    },
  },
  methods: {
    onInput(val) {
      this.$emit('update', val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.wrapper {
  .inner-wrapper {
    height: 50px;
  }
}

.input {
  display: flex;
  justify-content: space-between;
  svg {
    height: 21px;
    fill: var(--color-grey-darker);
  }
  .label {
    color: var(--color-grey-dark);
  }
  input {
    border: none;
    padding: 0;
    color: var(--color-grey-darker);
    font-size: var(--text-md-font-size);
    line-height: var(--text-md-line-height);
    font-weight: 400;
    line-height: var(--text-md-line-height);
    letter-spacing: var(--letter-spacing-sm);
    outline: none;
    width: 100%;
    &::placeholder {
      color: var(--color-grey-dark);
    }
    &:disabled {
      background-color: transparent;
      color: var(--color-grey-dark);
    }
  }
}
</style>
