<template>
  <n-bottom-sheet
    type="header"
    :title="$t('voucher.sheetTitle')"
    class="sheet"
    dismissible
    :closed="true"
    ref="sheet"
    @dismissed="$emit('dismissed')"
  >
    <n-text class="span-6" color="accent" preset="title">{{ $t('voucher.sheetHelpTitle') }}</n-text>
    <n-text class="span-6" color="grey-dark" preset="sub">{{ $t('voucher.sheetDescription') }}</n-text>
    <n-grid :y-gap="4">
      <n-signup-input class="span-6" v-model="voucher" :placeholder="$t('voucher.voucher')" />
    </n-grid>
    <n-button
      class="span-6"
      :loading="addingVoucher"
      @click="addVoucher"
      size="lg"
    >{{ $t('voucher.activateVoucher') }}</n-button>

    <n-dialog name="green-transport-consent">
      <i18n path="greenTransport.consentDialog.body">
        <template v-slot:termsLink>
          <a
            class="link"
            :href="$t('greenTransport.consentDialog.termsLink')"
            target="_blank"
          >{{ $t('greenTransport.consentDialog.termsLinkText') }}</a>
        </template>
        <template v-slot:companyName>{{ companyName }}</template>
      </i18n>
    </n-dialog>
  </n-bottom-sheet>
</template>

<script>
import store from '@/store';
import userApi from '@/api/user';
import voucherApi from '@/api/voucher';

export default {
  data: () => {
    return {
      voucher: store.state.user.voucher,
      addingVoucher: false,
      companyName: null,
    }
  },
  methods: {
    open() {
      this.$refs.sheet.open();
    },
    dismiss() {
      this.$refs.sheet.dismiss();
    },
    async addVoucher() {
      if (this.addingVoucher) {
        return;
      }

      let company = null;
      let used_previously = false;
      try {
        ({ company, used_previously } = await voucherApi.getVoucher(this.voucher));
      } catch (error) {
        //Not an company voucher
      }

      if (company && !used_previously) {
        const hasConsent = await this.getCompanyConsent(company);
        console.log('hasConsent', {hasConsent})
        this.$modal.hide('green-transport-consent');
        if (!hasConsent) return;
      }

      this.addingVoucher = true;

      try {
        store.commit('user/SET_VOUCHER', null);

        const resp = await userApi.addVoucher(this.voucher);

        this.voucher = null;
        store.dispatch('user/FETCH_PROFILE');

        this.dismiss();

        if (resp.voucher_type === 'FREE_KILOMETERS') {
          this.$success({
            description: this.$t('voucher.added_free_km'),
          });
        } else {
          this.$success({
            description: this.$t('voucher.added'),
          });
        }
      } catch (error) {
        this.$error({
          description: this.$t("voucher.failed"),
        });
      } finally {
        this.addingVoucher = false;
      }
    },
    async getCompanyConsent({ name }) {
      this.companyName = name;
      return new Promise((resolve) => {
        return this.$modal.show('green-transport-consent', {
          title: this.$t('greenTransport.consentDialog.title'),
          success: { handler: () => resolve(true) },
          cancelButton: { text: this.$t('greenTransport.consentDialog.cancel'), handler: () => resolve(false), },
          companyName: name,
        });
      });
    },
  }
}
</script>
